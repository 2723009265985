<template>
  <v-row justify="center" class="ml-0 mr-0 mb-0 mt-0">
    <!---------------------------------------------------------------
        Geo Targeting Dialog Form
    ----------------------------------------------------------------->
    <v-toolbar height="50" flat color="white">
    <v-spacer></v-spacer>
    <v-dialog :retain-focus="false" persistent v-model="masterGeoDialog" scrollable max-width="1700px" max-height="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          :disabled="readOnlyPools || submitting"
          dark
          class="addGeoBtn"
          color="teal"
          v-on="on"
          @click="loadData()"
        >
          Set Geo Tracking
          <v-icon right>mdi-earth</v-icon>
        </v-btn>
      </template>
      <v-card class="geoCard">
        <v-card-title>
          Set Geo Tracking
          <v-spacer></v-spacer>

          <v-btn-toggle v-if="masterGeoDialog"
            v-model="geo_inclusive"
            mandatory>
            <v-btn>Exclusive</v-btn>
            <v-btn>Inclusive</v-btn>
          </v-btn-toggle>
          <v-spacer></v-spacer>

          <v-combobox
            :items="countrySearch"
            @change="getTab"
            :search-input.sync="search"
            placeholder="Search For A Country"
            prepend-icon="mdi-magnify"
            single-line
            v-model="countryLookUp">
          </v-combobox>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text style="height: 500px" class="pl-0 pr-0 pb-0">
          <template v-if="overlay">
            <v-overlay :value="overlay">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
          </template>
          <template>
          <v-tabs
            v-model="tab"
            active-class="active-tab"
            background-color="primary accent-4"
            centered
            dark
            show-arrows>
            <v-tabs-slider></v-tabs-slider>
            <v-tab
                  class="pl-0 pr-0"
                  v-for="(tab,i) in tabs"
                  :key="i">
              <v-row>
                <v-col sm="12" class="pt-0 pb-0 pl-0 pr-0">{{tab.regionName}}</v-col>
                <v-col sm="12" class="pt-0 pb-0 pl-0 pr-0">{{tab.selectedCountries ? tab.selectedCountries.length : 0}}/{{tab.countries.length}} Selected</v-col>
              </v-row>
            </v-tab>
          </v-tabs>

          <v-row align="center" justify="center" class="ml-0 mr-0 mb-0 mt-0">
            <v-col sm="6" md="4" lg="3" class="mt-0 pt-0 pb-0">
              <v-radio-group v-if="tabs.length > 0" v-model="selection" justify="center" row class="justify-center">
                <v-radio @click="selectAll('select')" @change="selectAll('select')" value="select" class="hide-radio-icon mt-0">
                  <template v-slot:label>
                    <div @click="selectAll('select')">Select All</div>
                  </template>
                </v-radio>
                /
                <v-radio @click="selectAll('deselect')" @change="selectAll('deselect')" value="deselect" class="hide-radio-icon mt-0">
                  <template v-slot:label>
                    <div @click="selectAll('deselect')">Deselect All</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="tab in tabs" :key="tab.regionId">
              <v-card flat>
                <v-card-text class="countries pt-0">
                  <v-row v-if="masterGeoDialog" class="ml-0 mr-0 mb-0 mt-0">
                    <v-col
                      v-for="(country,i) in tab.countries"
                      sm="4" md="3" lg="2"
                      class="pt-0 pb-0 pl-0 pr-0"
                      :key="i">
                      <v-checkbox
                          @click="selectedCountry(country.countryCode, tabIndex)"
                          v-model="selectedCountries" class="mt-0 pt-0" :label="country.countryName" :value="country.countryCode">
                        <template v-slot:label>
                          <span :class="'span'+country.countryName.replace(/\s/g, '')">{{country.countryName}}</span>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          </template>
        </v-card-text>

        <v-divider></v-divider>
        <v-form ref="form" v-model="isValid">
          <v-card-text style="height: 180px">
            <v-row align="center" justify="center">
              <v-col class="pt-2 pb-0">
                <v-textarea
                  clearable
                  class="pb-0 preserve-whitespace"
                  counter
                  height="120px"
                  label="Targeting Notes"
                  name="input-7-4"
                  outlined
                  :rules="descriptionRules"
                  v-model="targeting_notes">
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeGeoDialog()">Cancel</v-btn>
            <v-btn
              @click="closeGeoDialog('apply')"
              color="blue darken-1"
              :disabled="!isValid"
              text
              >Apply</v-btn>
          </v-card-actions>
        </v-form>
        <!---------------------------------------------------------------
          Confirmation Dialog
        ----------------------------------------------------------------->
        <v-row justify="center" class="ml-0 mr-0 mb-0 mt-0">
          <v-dialog
            v-model="confirmDialog"
            max-width="450"
          >
            <v-card>
              <v-card-title class="headline">Override All Feeds in Pool?</v-card-title>
              <v-card-text>If no, this will only be applied to feeds without geo data</v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="green darken-1"
                  text
                  @click="overrideFeedGeo(false)">
                  No</v-btn>

                <v-btn
                  color="green darken-1"
                  text
                  @click="overrideFeedGeo(true)">
                  Yes</v-btn>

              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card>
    </v-dialog>
    </v-toolbar>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: ['submitting'],

  data: () => ({
    confirmDialog: false,
    countryLookUp: "",
    countriesAddedStart: new Set(),
    countriesRemovedStart: new Set(),
    descriptionRules: [v => (v || '').length <= 255 || 'Max 255 characters'],
    geo_inclusive: 1,
    isValid: true,
    masterGeoDialog: false,
    overlay: false,
    search: "",
    selection: null,
    selectedCountries: [],
    tab: null,
    targeting_notes: ""
  }),
  computed: mapState({
    countrySearch(){
      return this.$store.state.backfillPools.countrySearch;
    },
    editedItem() {
      return this.$store.state.backfillPools.editedItem;
    },
    geoCacheKey(){
      return this.$store.state.backfillPools.geoCacheKey;
    },
    readOnlyPools() {
      return this.$store.state.backfillPools.readOnlyPools;
    },
    tabIndex(){
      return this.tab;
    },
    tabs(){
      return this.$store.state.backfillPools.tabs;
    },
    unsavedGeo(){
      return this.$store.state.backfillPools.unsavedGeo;
    },
    userActivity(){
      return this.$store.state.backfillPools.userActivity;
    }
  }),

  methods: {
    ...mapMutations({
      addGeoCacheKey: "backfillPools/addGeoCacheKey",
      setCountrySearch: "backfillPools/setCountrySearch",
      setTabs: "backfillPools/setTabs",
      setUnsavedFeeds: "backfillPools/setUnsavedFeeds",
    }),

    closeGeoDialog(action = null){
      if(action == 'apply'){
          this.confirmDialog = true;
          this.setUnsavedFeeds(true);
      } else{
        this.masterGeoDialog = false;
        this.selectedCountries = [];
      }
    },
    dynamicSort(property) {
      var sortOrder = 1;

      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }

      return function (a,b) {
          if(sortOrder == -1){
              return b[property].localeCompare(a[property]);
          }else{
              return a[property].localeCompare(b[property]);
          }
      }
    },
    getTab(){
      if(this.countryLookUp && this.countrySearch.findIndex((x) => x.text == this.countryLookUp.text) > -1){
        let cName = JSON.parse(JSON.stringify(this.countryLookUp.text));
        //remove spaces
        cName = cName.replace(/\s/g, '');

        this.tab = Number(this.countryLookUp.value.charAt(0));
        let cCode = this.countryLookUp.value.split('-')[1];
        this.selectedCountry(cCode, this.tab, 'Add');

        setTimeout(function(){
          document.querySelector(".span" + cName).classList.add("highlight");},1000);

        setTimeout(function(){
            document.querySelector(".span" + cName).classList.remove('highlight');}, 2500);
      }
    },
    loadData(){
      if(this.tabs.length == 0){
        this.overlay = !this.overlay;
        Promise.all([
          this.axios
          .get(`/medusa/pools/get_regions_countries`)
          .then((res) => {
            this.setTabs(res.data);
            this.setGeoData();
            this.overlay = !this.overlay;
          })
        ]);
      } else {
        this.setGeoData();
      }
    },
    overrideFeedGeo(overrideAll = false){
      let user = this.$store.state.auth.user.username;
      let feeds = [];

      for(let feed of this.editedItem.typeData){
        if(overrideAll){
          feed.selectedCountries = this.selectedCountries;
          feed.geo_inclusive = this.geo_inclusive;
          feed.targeting_notes = this.targeting_notes;
          feed.geo_override = true;
          feeds.push(feed.feedUrl);
        } else{
          //Only override feeds with 0 selectedCountries
          if(feed.selectedCountries.length == 0){
            feed.selectedCountries = this.selectedCountries;
            feed.geo_inclusive = this.geo_inclusive;
            feed.targeting_notes = this.targeting_notes;
            feed.geo_override = true;
            feeds.push(feed.feedUrl);
          }
        }
        //Create Cache Key for all of the feeds for the given pool
        if(!this.geoCacheKey.includes(`${this.editedItem.pool_id}|`))
          this.addGeoCacheKey(`${this.editedItem.pool_id}|`);
      }
      if(feeds.length > 0){
        for(let feed of feeds){
          let activity = `Pool <b>${this.editedItem.name}</b> Overrode the Following URL's Geo Data:
                      <br><b>${feed}</b>
                       <br><u>${this.geo_inclusive == 1 ? 'Inclusive' : 'Exclusive'} Geo Tracking Countries to Include:</u> <b>${this.selectedCountries.toString()}</b>`;

          let action = [{user: user, activity: activity}];
          Array.prototype.push.apply(this.userActivity, action);
        }
      }

      this.editedItem.typeDataAltered = true;
      this.confirmDialog = false;
      this.masterGeoDialog = false;
      this.selectedCountries = [];
    },
    selectAll(choice){
      this.tabs[this.tab].selectedCountries = [];
      //Remove all countries for the region of the selected tab that exist in the feed level selected array
      this.selectedCountries = this.selectedCountries.filter(country => !this.tabs[this.tab].countries.map(name => name.countryCode).includes(country));

      if(choice == "select"){
        for(let country of this.tabs[this.tab].countries){
          this.tabs[this.tab].selectedCountries.push(country["countryCode"]);
          this.selectedCountries.push(country["countryCode"]);
        }
      } 
      this.selection = '';
    },
    selectedCountry(countryCode,tabIndex, action = null){
      /************
        Tab Level
      ************/
      let region = this.tabs[tabIndex];
      let countryIndex = region.selectedCountries.indexOf(countryCode);

      if(countryIndex > -1 && action != 'Add'){
        region.selectedCountries.splice(countryIndex,1);
      } else{
        //So that search functionality doesn't add the same value twice to a tab
        if(!region.selectedCountries.includes(countryCode)) region.selectedCountries.push(countryCode);
        if(!this.selectedCountries.includes(countryCode)) this.selectedCountries.push(countryCode);
      }
    },
    setGeoData(){
      let countries = [];
      for(let [index, tab] of this.tabs.entries()){
        tab["selectedCountries"] = [];
        //On initial run, setup country search autocomplete
        if(this.countrySearch.length == 0){
          for(let [i, country] of tab.countries.entries()){
            //index is the tab #
            countries.push({text: country.countryName, value: index + "_" + i + "-" + country.countryCode});
          }
        }
      }
      if(this.countrySearch.length == 0)
        this.setCountrySearch(countries.sort(this.dynamicSort("text")));
    }
  },
};
</script>
<style scoped lang="scss">
  .active-tab{
    background: black;
  }
  ::v-deep .geoCard {
    .v-input__slot {
      margin-bottom: 0 !important;
    }
    .v-tab {
      font-size: 12.5px;
      text-transform: none;
    }
    .highlight {
      background: yellow;
    }
    .v-messages{
      min-height: 10px;
    }
  }
  .countries {
    max-height: 500px;
    overflow-y: scroll;
  }
  .row { max-width: 100%; }

  ::v-deep .hide-radio-icon{
    i, .v-input--selection-controls__ripple, input{
      display: none;
    }
    &:last-child {
      label:last-child {
        margin-left: -15px;
      }
    }
    label {
      color: blue;
      text-decoration: underline;
    }
  }
  ::v-deep .hide-radio-icon i, ::v-deep .hide-radio-icon .v-input--selection-controls__ripple {
    display: none;
  }
  ::v-deep .addGeoBtn.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #009688 !important;
    border-color: #009688 !important;
    opacity: .5;
  }
</style>