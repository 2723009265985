<template>
  <v-row justify="center" class="ml-0 mr-0 mb-1 mt-0">
    <v-dialog v-model="historyLogDialog" scrollable max-width="1500px">
      <template v-slot:activator="{ on }">
        <v-btn
          small
          class="historyLogBtn"
          color="error"
          v-on="on"
          @click="getBackfillPoolsHistoryLog()"
        >
          View History Log
          <v-icon right>mdi-calendar-text-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          Backfill Pools History Log
          <v-spacer></v-spacer>
          <v-text-field
            class="historyLogSearch"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px" class="pl-0 pr-0 pb-0">
          <template v-if="overlay">
            <v-overlay :value="overlay">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
          </template>

          <template v-else>
            <v-data-table
              :fixed-header="fixedHeader"
              :loading="loading"
              :headers="headers"
              :items="historyLog"
              :search="search"
              class="historyLogTable elevation-1"
              :footer-props="{ 'items-per-page-options': [10, 30, 50, -1] }"
            >
              <template v-slot:item.activity="{ item }">
                <tr :key="item.id">
                  <td v-html="item.activity"></td>
                </tr>
              </template>
            </v-data-table>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="historyLogDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    historyLogDialog: false,
    overlay: false,
    fixedHeader: true,
    loading:false,
    headers: [
      {
        text: "User",
        align: "start",
        value: "username",
        width: "10%"
      },
      {
        text: "Timestamp",
        value: "timestamp",
        width: "25%",
        align: "center"
      },
      {
        text: "Activity",
        value: "activity",
        align: "start"
      }
    ],
    search: ""
  }),
  computed: mapState({
    historyLog() {
      return this.$store.state.backfillPools.historyLog.map((log) => {
        log['timestamp'] = new Date(log.timestamp.replace(/-/g,"/") + ' GMT+8').toLocaleString();
        return Object.assign({}, log, {});
      });
    },
    historyLogLoaded: (state) => {
      return state.backfillPools.historyLogLoaded;
    }
  }),

  methods: {
    ...mapMutations({
      setHistoryLog: "backfillPools/setHistoryLog",
      setHistoryLogLoaded: "backfillPools/setHistoryLogLoaded"
    }),
    getBackfillPoolsHistoryLog() {
      if (!this.historyLogLoaded) {
        this.loading = !this.loading;
        this.overlay = !this.overlay;
        return new this.$axios.get("/medusa/pools/backfill_pools_history_log").then((res) => {
          this.setHistoryLog(res.data);
          this.overlay = false;
          this.loading = false;
          this.setHistoryLogLoaded(true);
        });
      }
    }
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    }
  }
};
</script>
<style scoped lang="scss">
 ::v-deep .historyLogTable .v-data-table__wrapper{
    height: 62vh;
  }
::v-deep table {
  max-height: 700px;
  thead.v-data-table-header {
    th {
      color: white !important;
      background: rgba(0, 0, 0, 0.8) !important;
    }
  }
}
::v-deep thead {
  background: rgba(0, 0, 0, 0.8);
  tr {
    th {
      color: white !important;
    }
  }
}

::v-deep tr:nth-of-type(even) {
  background-color: rgba(150, 150, 150, 0.2);
}

.historyLogBtn {
  height: 20px !important;
}
.historyLogSearch .mdi-magnify {
  margin-bottom: 25px !important;
}
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 35px !important;
  }
@media (max-height: 845px) {
  ::v-deep .historyLogTable .v-data-table__wrapper{
    height: 64vh;
  }
}
@media (max-height: 725px) {
  ::v-deep .historyLogTable .v-data-table__wrapper{
    height: 60vh;
  }
}
@media (max-height: 625px) {
  ::v-deep .historyLogTable .v-data-table__wrapper{
    height: 55vh;
  }
}
</style>