<template>
  <v-row justify="center" class="ml-0 mr-0 mb-1 mt-0">
    <!---------------------------------------------------------------
        Geo Targeting Dialog Form
    ----------------------------------------------------------------->
    <v-toolbar height="50" flat color="white">
    <v-spacer></v-spacer>
    <v-dialog :retain-focus="false" persistent v-model="$store.state.backfillPools.geoDialog" scrollable max-width="1700px" max-height="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          block
          large
          dark
          class="addGeoBtn"
          color="teal"
          v-on="on"
          @click="loadData()"
        >
          Set Geo Tracking
          <v-icon right>mdi-earth</v-icon>
        </v-btn>
      </template>
      <v-card class="geoCard">
        <v-card-title>
          {{editedFeedIndex == -1 ? "Add Geo Tracking" : "Update Geo Tracking"}}

          <v-spacer></v-spacer>

          <v-btn-toggle v-if="geoDialog"
            @change="feedData.geo_inclusive_changed = true"
            v-model="feedData.geo_inclusive"
            mandatory>
            <v-btn :disabled="readOnlyPools">Exclusive</v-btn>
            <v-btn :disabled="readOnlyPools">Inclusive</v-btn>
          </v-btn-toggle>
          <v-spacer></v-spacer>

          <v-combobox
            :items="countrySearch"
            @change="getTab"
            :search-input.sync="search"
            placeholder="Search For A Country"
            prepend-icon="mdi-magnify"
            :readyonly="readOnlyPools"
            single-line
            v-model="countryLookUp">
          </v-combobox>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text style="height: 600px" class="pl-0 pr-0 pb-0">
          <template v-if="overlay">
            <v-overlay :value="overlay">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
          </template>
          <template>
          <v-tabs
            v-model="tab"
            active-class="active-tab"
            background-color="primary accent-4"
            centered
            dark
            show-arrows>
            <v-tabs-slider></v-tabs-slider>
            <v-tab
                  class="pl-0 pr-0"
                  v-for="(tab,i) in tabs"
                  :key="i">

              <v-row>
                <v-col sm="12" class="pt-0 pb-0 pl-0 pr-0">{{tab.regionName}}</v-col>
                <v-col sm="12" class="pt-0 pb-0 pl-0 pr-0">{{tab.selectedCountries ? tab.selectedCountries.length : 0}}/{{tab.countries.length}} Selected</v-col>
              </v-row>
            </v-tab>
          </v-tabs>

          <v-row align="center" justify="center" class="ml-0 mr-0 mb-0 mt-0">
            <v-col sm="6" md="4" lg="3" class="mt-0 pt-0 pb-0">
              <v-radio-group v-if="tabs.length > 0" v-model="selection" :disabled="readOnlyPools" justify="center" row class="justify-center">
                <v-radio @click="selectAll('select')" @change="selectAll('select')" value="select" class="hide-radio-icon">
                  <template v-slot:label>
                    <div @click="selectAll('select')">Select All</div>
                  </template>
                </v-radio>
                /
                <v-radio @click="selectAll('deselect')" @change="selectAll('deselect')" value="deselect" class="hide-radio-icon">
                  <template v-slot:label>
                    <div @click="selectAll('deselect')">Deselect All</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="tab in tabs" :key="tab.regionId">
              <v-card flat>
                <v-card-text class="countries">
                  <v-row v-if="geoDialog" >
                    <v-col
                      v-for="(country,i) in tab.countries"
                      sm="4" md="3" lg="2"
                      class="pt-0 pb-0 pl-0 pr-0"
                      :key="i">
                      <v-checkbox
                          @click="selectedCountry(country.countryCode, tabIndex)"
                          :disabled="readOnlyPools"
                          v-model="feedData.selectedCountries" class="mt-0" :label="country.countryName" :value="country.countryCode">
                        <template v-slot:label>
                          <span :class="'span'+country.countryName.replace(/\s/g, '')">{{country.countryName}}</span>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          </template>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeGeoDialog()">Cancel</v-btn>
          <v-btn
            v-if="!readOnlyPools"
            @click="closeGeoDialog('saved')"
            color="blue darken-1"
            text
            >{{editedFeedIndex == -1 ? "Add" : "Update" }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-toolbar>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    countryLookUp: "",
    countriesAddedStart: new Set(),
    countriesRemovedStart: new Set(),
    overlay: false,
    search: "",
    selection: null,
    selectedCountriesStart: [],
    tab: null,
  }),

  computed: mapState({
    countrySearch(){
      return this.$store.state.backfillPools.countrySearch;
    },
    defaultFeed(){
      return this.$store.state.backfillPools.defaultFeed;
    },
    editedFeedIndex(){
      return this.$store.state.backfillPools.editedFeedIndex;
    },
    editedIndex() {
      return this.$store.state.backfillPools.editedIndex;
    },
    editedItem() {
      return this.$store.state.backfillPools.editedItem;
    },
    feedData() {
      if(this.editedFeedIndex != -1)
        return this.editedItem.typeData[this.editedFeedIndex];
      else
        return this.defaultFeed;
    },
    geoDialog(){
      return this.$store.state.backfillPools.geoDialog;
    },
    origPools(){
      return this.$store.state.backfillPools.origPools;
    }, 
    readOnlyPools() {
      return this.$store.state.backfillPools.readOnlyPools;
    },
    tabIndex(){
      return this.tab;
    },
    tabs(){
      return this.$store.state.backfillPools.tabs;
    },
    unsavedGeo(){
      return this.$store.state.backfillPools.unsavedGeo;
    }
  }),

  methods: {
    ...mapMutations({
      setGeoDialog: "backfillPools/setGeoDialog",
      setCountrySearch: "backfillPools/setCountrySearch",
      setTabs: "backfillPools/setTabs",
      setUnsavedGeo: "backfillPools/setUnsavedGeo",
    }),

    closeGeoDialog(action = null){
      this.setGeoDialog(false);
      //Reset Geo Data
      if(action != "saved"){
        //For When dialog is updated and then opened any # of more times before canceling/updated the edit feed url dialog
        if(this.editedFeedIndex != -1 && this.feedData.feedId != null){
          this.editedItem.typeData[this.editedFeedIndex].selectedCountries = this.selectedCountriesStart;
          this.editedItem.typeData[this.editedFeedIndex].countriesAdded = new Set(this.countriesAddedStart);
          this.editedItem.typeData[this.editedFeedIndex].countriesRemoved = new Set(this.countriesRemovedStart);
        }
        this.setUnsavedGeo(false);
      }
    },
    //To be converted to a mixin
    dynamicSort(property) {
      var sortOrder = 1;

      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }

      return function (a,b) {
          if(sortOrder == -1){
              return b[property].localeCompare(a[property]);
          }else{
              return a[property].localeCompare(b[property]);
          }
      }
    },
    /**
     * Show the tab of the country being searched for, highlight it, and add it to the selected countries
     */
    getTab(){
      if(this.countryLookUp && this.countrySearch.findIndex((x) => x.text == this.countryLookUp.text) > -1){
        let cName = JSON.parse(JSON.stringify(this.countryLookUp.text));
        //remove spaces
        cName = cName.replace(/\s/g, '');

        this.tab = Number(this.countryLookUp.value.charAt(0));
        if(!this.readOnlyPools) {
          let cCode = this.countryLookUp.value.split('-')[1];
          this.selectedCountry(cCode, this.tab, 'Add');
        }

        setTimeout(function(){
          document.querySelector(".span" + cName).classList.add("highlight");},1000);

        setTimeout(function(){
            document.querySelector(".span" + cName).classList.remove('highlight');}, 2500);
      }
    },

    /**
     * Get the current list of regions & their countries and then set the feeds selected countries
     */
    loadData(){
      //Only call this once per page load
      if(this.tabs.length == 0){
        this.overlay = !this.overlay;
        Promise.all([
          this.axios
          .get(`/medusa/pools/get_regions_countries`)
          .then((res) => {
            this.setTabs(res.data);
            this.setGeoData();
            this.overlay = !this.overlay;
          })
        ]);
      } else {
        this.setGeoData();
      }

      this.selectedCountriesStart = JSON.parse(JSON.stringify(this.feedData.selectedCountries));

      if(this.feedData.countriesAdded.size > 0){
        this.feedData.countriesAdded.forEach((item) => this.countriesAddedStart.add(item));
      } else {
        //Needed to prevent this from turning into an array after initial save
        this.countriesAddedStart = new Set();
      }
      if(this.feedData.countriesRemoved.size > 0){
        this.feedData.countriesRemoved.forEach((item) => this.countriesRemovedStart.add(item));
      } else {
        //Needed to prevent this from turning into an array after initial save
        this.countriesRemovedStart = new Set();
      }

    },
    /**
     * Select/Deselect All Countires In A Given Region
     */
    selectAll(choice){
      this.setUnsavedGeo(true);
      this.tabs[this.tab].selectedCountries = [];
      //Get list of countries that are being deselected to add to countriesRemovied if deselect all is clicked
      let currentCountries = JSON.parse(JSON.stringify(this.feedData.selectedCountries.filter(country => this.tabs[this.tab].countries.map(name => name.countryCode).includes(country))));
      //Remove all countries for the region of the selected tab that exist in the feed level selected array
      this.feedData.selectedCountries = this.feedData.selectedCountries.filter(country => !this.tabs[this.tab].countries.map(name => name.countryCode).includes(country));


      if(choice == "select"){
        for(let country of this.tabs[this.tab].countries){
          this.tabs[this.tab].selectedCountries.push(country["countryCode"]);
          this.feedData.selectedCountries.push(country["countryCode"]);
          //If feed exists track newly added countries
          if(this.feedData.feedId){
            if(this.feedData.countriesRemoved.has(country["countryCode"])){
              this.feedData.countriesRemoved.delete(country["countryCode"]);
            } else {
              if(!currentCountries.includes(country["countryCode"])) this.feedData.countriesAdded.add(country["countryCode"]);
            }
          }
        }
      } else {
        for(let country of currentCountries){
          //If feed exists track newly removed countries
          if(this.feedData.feedId){
            if(this.feedData.countriesAdded.has(country)){
              this.feedData.countriesAdded.delete(country);
            } else {
              this.feedData.countriesRemoved.add(country);
            }
          }
        }
      }
      this.selection = '';
    },
    /**
     * When a country is added/removed, make sure tab country count is accurate and track the actions
     */
    selectedCountry(countryCode,tabIndex, action = null){
      this.setUnsavedGeo(true);
      /************
        Tab Level
      ************/
      let region = this.tabs[tabIndex];
      let countryIndex = region.selectedCountries.indexOf(countryCode);

      if(countryIndex > -1 && action != 'Add'){
        region.selectedCountries.splice(countryIndex,1);
        //If feed exists track removed countries
        if(this.feedData.feedId){
          if(this.feedData.countriesAdded.has(countryCode)){
            this.feedData.countriesAdded.delete(countryCode);
          } else {
            this.feedData.countriesRemoved.add(countryCode);
          }
        }
      } else{
        //So that search functionality doesn't add the same value twice to a tab
        if(!region.selectedCountries.includes(countryCode)) region.selectedCountries.push(countryCode);
        if(!this.feedData.selectedCountries.includes(countryCode)) this.feedData.selectedCountries.push(countryCode);

        //If feed exists track added countries
        if(this.feedData.feedId){
          if(this.feedData.countriesRemoved.has(countryCode)){
            this.feedData.countriesRemoved.delete(countryCode);
          } else {
            this.feedData.countriesAdded.add(countryCode);
          }
        }
      }
    },
    /**
     * Set the currently selectedCountries for the feed
     */
    setGeoData(){
      let countries = [];
      for(let [index, tab] of this.tabs.entries()){
        tab["selectedCountries"] = [];
        //Add Countires That are selected to the assocaited regions selected array after comparing the regions countries to the feeds selected countries
        tab.selectedCountries = tab.countries.filter(country => this.feedData.selectedCountries.includes(country.countryCode)).map(({countryCode}) => countryCode);

        //On initial run, setup country search autocomplete
        if(this.countrySearch.length == 0){
          for(let [i, country] of tab.countries.entries()){
            //index is the tab #
            if(country.countryName == 'US')
              country.countryName = 'United States';

            countries.push({text: country.countryName, value: index + "_" + i + "-" + country.countryCode});
          }
        }
      }
      if(this.countrySearch.length == 0)
        this.setCountrySearch(countries.sort(this.dynamicSort("text")));
    }
  },
};
</script>
<style scoped lang="scss">
  .active-tab{
    background: black;
  }
  ::v-deep .geoCard {
    .v-input__slot {
      margin-bottom: 0 !important;
    }
    .v-tab {
      font-size: 12.5px;
      text-transform: none;
    }
    .highlight {
      background: yellow;
    }
  }
  .countries {
    max-height: 500px;
    overflow-y: scroll;
  }
  .row { max-width: 100%; }

  ::v-deep .hide-radio-icon{
    i, .v-input--selection-controls__ripple, input{
      display: none;
    }
    &:last-child {
      label:last-child {
        margin-left: -15px;
      }
    }
    label {
      color: blue;
      text-decoration: underline;
    }
  }
  ::v-deep .hide-radio-icon i, ::v-deep .hide-radio-icon .v-input--selection-controls__ripple {
    display: none;
  }
</style>